import { useEffect, useState, useMemo, ReactElement } from "react";

import Card from "components/ui/Card";
import LoadingView from "components/ui/LoadingView";
import useQuery from "hooks/useQuery";
import { getWeather, getPhoto } from "api";
import { OneCallWeather } from "models/api";
import { format, isBefore, addDays, differenceInHours } from "date-fns";
import arrowIcon from "assets/icons/arrow.svg";
import WeatherChart from "components/WeatherChart";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import Nav from "components/ui/Nav";
import SearchBox from "components/ui/SearchBox";
import { Popover } from '@headlessui/react';

interface Props {

}

interface LatLong {
  lat: number,
  long: number
};

const mergeHourlyByWeatherType = (hourlyData:any) => {
  if (!hourlyData) {
    return [];
  }

  const mergedData = [];
  let lastWeather = null;
  let minTime = 0;

  for (let i = 0; i < hourlyData.length; i++) {
    if (minTime === 0) {
      minTime = hourlyData[i].dt;
    }
    if (!lastWeather) {
      lastWeather = hourlyData[i];
    }

    if (lastWeather && hourlyData[i].weather[0].id !== lastWeather.weather[0].id) {
      mergedData.push({
        minTime,
        maxTime: hourlyData[i].dt,
        hourDiff: differenceInHours(hourlyData[i].dt * 1000, minTime * 1000),
        weather: lastWeather.weather[0]
      });
      lastWeather = null;
      minTime = hourlyData[i].dt;
    }
  }

  if (mergedData.length === 0) {
    mergedData.push({
      minTime,
      maxTime: hourlyData[hourlyData.length - 1].dt,
      weather: hourlyData[hourlyData.length - 1].weather[0],
      hourDiff: differenceInHours(hourlyData[hourlyData.length - 1].dt * 1000, minTime * 1000),
    });
  }

  return mergedData;
};

const Weather = (props: Props) => {
  const query = useQuery();
  const location = query.get("location");
  const name = query.get("name");
  const [latLong, setLatLong] = useState<LatLong | null>(null);
  const [resp, setResp] = useState<OneCallWeather | null>(null);
  const [photoResp, setPhotoResp] = useState<any>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const history = useHistory();

  const photoUrl = useMemo<string | null>(() => {
    if (photoResp && photoResp.results && photoResp.results.length > 0) {
      return photoResp.results[0].urls.regular;
    }
    return null;
  }, [photoResp]);

  const photoAttrib = useMemo<ReactElement | null>(() => {
    if (photoResp && photoResp.results && photoResp.results.length > 0) {
      const user = photoResp.results[0].user;
      const link = photoResp.results[0].links.html;
      const userLink = user.links.html;
      return <p className="u-margin-none"><a href={link} target="_blank" rel="noreferrer">Image Source</a> - <a href={userLink} target="_blank" rel="noreferrer">{user.name}</a></p>
    }
    return null;
  }, [photoResp]);

  const currentWeather = useMemo<any>(() => {
    return resp?.current;
  }, [resp]);

  const hourlyBreakdownByWeather = useMemo<Array<any>>(() => {
    if (resp) {
      // Get the next 24 hours merged by weather type.
      return mergeHourlyByWeatherType(resp.hourly.filter(item => isBefore(item.dt * 1000, addDays(new Date(), 1))))
    };

    return [];
  }, [resp]);

  useEffect(() => {
    if (location) {
      // Parse to ints.
      const strs = location.split(",");

      if (strs.length === 2) {
        const lat = parseFloat(strs[0]);
        const long = parseFloat(strs[1]);

        setLatLong({lat, long});
        setIsFetching(true);
        getWeather(lat, long).then(resp => {
          setResp(JSON.parse(resp.text));
        }).finally(() => setIsFetching(false));
      }
    }
  }, [location]);

  useEffect(() => {
    if (name) {
      getPhoto(name).then(resp => {
        setPhotoResp(JSON.parse(resp.text));
      })
    }
  }, [name]);


  useEffect(() => {
    if (window.localStorage) {
      localStorage.setItem("weather-app-default", history.createHref(history.location));
    }
  }, [history])

  return (
    <>
      <Nav>
        <SearchBox linkPrefix="/weather" />
      </Nav>
      <article>
        {(!location || !latLong) && <Card>
          <h1>Oops, could not find location!</h1>
          <p>Try searching for another place!</p>
        </Card>}

        <LoadingView isLoading={isFetching} className="c-weather" style={{position: isFetching ? "relative" : "unset" }}>
          <div className="c-weather__header" style={{backgroundImage: photoUrl ? `url(${photoUrl})` : ""}}>
            <div className="c-weather__header-inner">
              {currentWeather && <i className={`wi wi-owm-${currentWeather.weather[0].id} c-weather__header-icon u-margin-right-small`}></i>}
              <div>
                <h1 className="u-margin-none">{name}</h1>
              {currentWeather && <p className="c-weather__header-description u-margin-none"><strong>{currentWeather.temp.toFixed(0)}˚</strong> {currentWeather.weather[0].description}</p>}
              </div>
            </div>
            <div className="c-weather__image-attrib">
              {photoAttrib}
            </div>
          </div>
          <Card className="u-margin-top">
            <h2 className="u-margin-top-none">Right now</h2>
            <div className="c-weather__current">
              <ul className="c-weather__current-list">
                <li className="c-weather__current-list-item">Sunrise: <span className="u-text-bold">{resp && format(new Date(resp?.current.sunrise * 1000), "HH:mm")}</span></li>
                <li className="c-weather__current-list-item">Sunset: <span className="u-text-bold">{resp && format(new Date(resp?.current.sunset * 1000), "HH:mm")}</span></li>
                <li className="c-weather__current-list-item">Temperature: <span className="u-text-bold">{resp?.current.temp.toFixed(0)}°C</span></li>
                <li className="c-weather__current-list-item">Feels like: <span className="u-text-bold">{resp?.current.feels_like.toFixed(0)}°C</span></li>
                <li className="c-weather__current-list-item">Pressure: <span className="u-text-bold">{resp?.current.pressure}hpa</span></li>
                <li className="c-weather__current-list-item">Humidity: <span className="u-text-bold">{resp?.current.humidity}%</span></li>
                <li className="c-weather__current-list-item">Wind speed: <span className="u-text-bold">{resp?.current.wind_speed.toFixed(0)}m/s <img role="presentation" alt="" src={arrowIcon} className="c-weather__arrow-icon" style={{transform: `rotate(${resp?.current.wind_deg}deg)`}}/></span></li>
              </ul>
            </div>
          </Card>

          <Card className="u-margin-top">
            <h2 className="u-margin-top-none">Hourly</h2>
            <div className="c-weather__hourly-scroll">
              {hourlyBreakdownByWeather &&
                <ol className="o-bare-list c-weather__hourly">
                  {hourlyBreakdownByWeather.map((item, index) => (
                  <li key={item.minTime} className="c-weather__hourly-item" style={{ width: `${(item.hourDiff / 24) * 100}%` }}>
                    <Popover className="c-weather__pop-up">
                      <Popover.Button className={classNames("c-weather__hourly-item-inner u-flex u-flex--justify-center o-bare-button", `c-weather__hourly-item--${item.weather.id}`)}>
                        <span className="c-weather__hourly-tick">{format(item.minTime * 1000, "ha")}</span>
                        <span>
                          <i className={`wi wi-owm-${item.weather.id}`}></i>{" "}
                          <span className={classNames("u-text-bold", item.hourDiff < 5 && "u-visually-hidden")}>{item.weather.description}</span>
                        </span>
                        {index === hourlyBreakdownByWeather.length - 1 && <span className="c-weather__hourly-tick">{format(item.maxTime * 1000, "ha")}</span>}
                      </Popover.Button>

                      <Popover.Panel className="c-weather__pop-up-panel">
                        <i className={`wi wi-owm-${item.weather.id}`}></i>{" "}
                        <span className="u-text-bold">{item.weather.description}</span>
                      </Popover.Panel>
                    </Popover>
                  </li>
                  ))}
                </ol>
              }
            </div>

            {resp && <WeatherChart weatherData={resp.hourly}/>}
          </Card>

          <Card className="u-margin-top">
            <h2 className="u-margin-top-none">Next 7 days</h2>
            {resp && <ol className="o-bare-list c-weather__daily-list">
              {resp.daily.map(day => (
                <li key={day.dt} className="c-weather__daily-list-item">
                  <i className={`wi wi-owm-${day.weather[0].id}`}></i>{" "}
                  {format(day.dt * 1000, "E do")}{" "}
                  <span className="u-text-bold">{day.weather[0].description}</span>{", "}
                  {day.temp.min.toFixed(0)}°C - {day.temp.max.toFixed(0)}°C
                </li>
              ))}
            </ol>}
          </Card>

          {process.env.NODE_ENV === "development" && <Card className="u-margin-top">
            <details>
              <summary>JSON</summary>
              <pre>
                <code className="u-margin-bottom">
                  {JSON.stringify(photoResp, null, 2)}
                </code>
                <code>
                  {JSON.stringify(resp, null, 2)}
                </code>
              </pre>
            </details>
          </Card>}
        </LoadingView>

      </article>
    </>
  )
}

export default Weather;