import { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom"
import Components from "pages/components";
import Weather from "pages/weather";
import SearchBox from "components/ui/SearchBox";
import Nav from "components/ui/Nav";

const Routes = () => {
  const history = useHistory();

  useEffect(() => {
    if (window.localStorage) {
      const path = window.localStorage.getItem("weather-app-default");
      if (path && history) {
        history.push(path);
      }
    }
  }, [history]);

  return (
    <Switch>
      <Route path="/ui" component={Components} />
      <Route path="/weather" component={Weather} />
      <Route>
        <Nav isMiddle>
          <SearchBox linkPrefix="/weather" />
        </Nav>
      </Route>
    </Switch>
  )
}

export default Routes;