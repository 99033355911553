import {
  BrowserRouter as Router
} from "react-router-dom";
import Routes from "routes";

const App = () => {
  return (
    <Router>
      <main className="App">
          <Routes />
      </main>
    </Router>
  );
}

export default App;
