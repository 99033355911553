import { useEffect } from "react";

/**
 * Hook that alerts clicks or key down outside of the passed ref
 */
const useOutsideAlerter = (ref, onClickOutside) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleClickOutside);
    document.addEventListener("blur", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleClickOutside);
      document.removeEventListener("blur", handleClickOutside);
    };
  }, [ref, onClickOutside]);
};

export default useOutsideAlerter;
