import { ReactNode, useState, useEffect, useRef } from "react";
import cloudSvg from "assets/icons/cloud.svg";
import classnames from "classnames";

const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
}

interface Props {
  isLoading: boolean,
  children?: ReactNode,
  numberOfClouds?: number,
  cloudMaxWidth?: number,
  className?: string,
  // 'Indexer'
  [x: string]: any
};

interface Cloud {
  width: number,
  top: number,
  left: number,
  speed: number
};

interface Dimensions {
  width: number,
  height: number
};

const LoadingView = ({isLoading, children, numberOfClouds = 25, cloudMaxWidth = 50, className, ...rest}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [interval, setIntervalVal] = useState<number>(-1);

  const [clouds, setClouds] = useState<Array<Cloud>>([...Array(numberOfClouds)].map(() => ({
    width: getRandomInt(cloudMaxWidth),
    top: 0,
    left: 0,
    speed: getRandomInt(10) || 1
  })));

  const [hasInit, setHasInit] = useState<boolean>(false);

  const [dimensions, setDimensions] = useState<Dimensions>({width: 0, height: 0});

  useEffect(() => {
    const logRef = (el?: HTMLDivElement) => {
      setDimensions({
        width: el ? el.offsetWidth : 0,
        height: el ? el.offsetHeight : 0
      });
    };

    const callback = () => logRef(null !== ref.current ? ref.current : undefined);

    if (null !== ref.current) {
      callback();
    }

    window.addEventListener("resize", callback);

    return () => {
      window.removeEventListener("resize", callback);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  useEffect(() => {
    if (interval > -1) {
      clearInterval(interval);
    }

    const updateClouds = (isNew: boolean) => {
      if (isNew) {
        setClouds(clouds.map(cloud => {
          return {
            ...cloud,
            top: getRandomInt(dimensions.height),
            left: getRandomInt(dimensions.width),
          }
        }));
      } else {
        setClouds(clouds.map(cloud => {
          const newCloud = cloud;
          newCloud.left -= cloud.speed;

          if (newCloud.left < -cloudMaxWidth) {
            newCloud.left = dimensions.width;
          }
          return newCloud;
        }));
      }
    };

    setIntervalVal(window.setInterval(() => updateClouds(false), 300));

    if (!hasInit && dimensions.width) {
      updateClouds(true);
      setHasInit(true);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensions]);

  return (
    <div className={classnames("c-loading-view", className)} ref={ref} {...rest}>
      {isLoading && (<div className="c-loading-view__loading-screen">
        <p className="u-visually-hidden">Loading</p>
        {
          clouds.map((cloud, index) => (
            <img className="c-loading-view__cloud"
                 src={cloudSvg}
                 role="presentation"
                 alt=""
                 key={index}
                 width={cloud.width}
                 style={{left: cloud.left, top: cloud.top}} />
          ))
        }
      </div>)}
      {!isLoading && children}
    </div>
  );
};

export default LoadingView;
