import superagent from "superagent";

export const apiUrl = ():String => {
  return window.location.hostname === "localhost" ? "http://localhost:9000" : `https://${window.location.hostname}/.netlify/functions`;
};

export const searchLocation = (query: String): Promise<superagent.Response> => {
  const url = `${apiUrl()}/location?query=${query}`;

  return superagent.get(url);
};

export const getWeather = (lat: number, long: number): Promise<superagent.Response> => {
  const url = `${apiUrl()}/weather?lat=${lat}&lng=${long}`;

  return superagent.get(url);
};

export const getPhoto = (query: string): Promise<superagent.Response> => {
  const url = `${apiUrl()}/photo?query=${query}`;

  return superagent.get(url);
};