import useDebounce from 'hooks/useDebounce';
import { useState, useEffect, useRef } from 'react'
import Input from '../Form/input'
import Label from '../Form/label';
import classnames from "classnames";
import useOutsideAlerter from 'hooks/useOutsideAlerter';
import { Link } from 'react-router-dom';
import { searchLocation } from 'api';
import {Response } from "superagent";

interface Props {
  inputId?: string,
  inputLabel?: string,
  formAriaLabel?: string,
  className?: string,
  linkPrefix?: string
};

interface SearchResult {
  label: string,
  value: string,
  location: {
    lat: number,
    lng: number
  }
};

const SearchBox = ({inputId = "search-box", inputLabel = "Search", formAriaLabel = "Sitewide", className = "", linkPrefix="/"}: Props) => {
  const [searchResults, setSearchResults] = useState<Array<SearchResult>>([]);
  const [searchValue, setSearchValue] = useState<String>("");
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const formRef = useRef(null);

  const clearResults = () => {
    setSearchResults([]);
    setSearchValue("");
  };

  useOutsideAlerter(formRef, clearResults);

  useEffect(() => {
    if (debouncedSearchTerm) {
      searchLocation(debouncedSearchTerm)
        .then((resp: Response) => {
          if (resp.text) {
            const parsed = JSON.parse(resp.text);
            setSearchResults(parsed.map((item: any, index: number): SearchResult => ({label: item.displayLines.join(", "), value: index.toString(), location: {lat: item.coordinate.latitude, lng: item.coordinate.longitude}})))
          }
        })
    } else {
      setSearchResults([]);
    }
  }, [debouncedSearchTerm]);
  return (
      <form role="search" aria-label={formAriaLabel} className={classnames("c-search-box", className)} ref={formRef}>
        <fieldset>
          <Label htmlFor={inputId} className="u-visually-hidden">{inputLabel}</Label>
          <Input className={classnames("c-search-box__input", searchResults.length > 0 && "c-search-box__input--has-results")} type="text" id={inputId} role="combobox" value={searchValue} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)} placeholder={inputLabel}/>

          {searchResults.length > 0 &&
            <ul role="listbox" className="c-search-box__listbox">
              {searchResults.map((item: SearchResult) =>
                <li key={item.value} className="c-search-box__listbox-item">
                  <Link to={`${linkPrefix}/?location=${item.location.lat},${item.location.lng}&name=${item.label}`} onClick={clearResults}>{item.label}</Link>
                </li>
              )}
            </ul>
          }
        </fieldset>
      </form>
  );
};

export default SearchBox;
