import { useState, useMemo, useEffect } from "react";
import Chart from 'chart.js/auto';
import { ChartType, ChartData } from 'chart.js';
import { RadioGroup } from '@headlessui/react'
import classnames from "classnames";
import { format } from "date-fns";

interface Props {
  className?: string,
  weatherData: any
};

const lineType:ChartType = "line";

const valueLabels: {[key:string]: string} = {
  temp: "Temperature",
  feels_like: "Feels like",
  wind_speed: "Wind Speed"
}

const valueUnits: {[key:string]: string} = {
  temp: "°C",
  feels_like: "°C",
  wind_speed: "m/s"
}

const DEFAULTS_SCALES = {
  x: {
    grid: {
      display: false
    }
  },
  y: {
    grid: {
      display: false
    },
    ticks: {}
  }
}

const getKeyValue = <T extends object, U extends keyof T>(obj: T) => (key: U) =>
  obj[key];


const WeatherChart = ({className = "", weatherData}: Props) => {
  const [canvasEl, setCanvasEl] = useState<HTMLCanvasElement | null>(null);
  const [weatherChart, setWeatherChart] = useState<Chart | null>(null);
  const [dataKey, setDataKey] = useState<string>("temp");

  const chartWeatherData = useMemo<ChartData>(() => {
    const data = weatherData ? weatherData.map((item: { [x: string]: any; }) => item[dataKey]) : [];
    const labels = weatherData?.map((item: { dt: number; }) => format(new Date(item.dt * 1000), "HH:mm"));

    return {
      labels,
      datasets: [{
        label: getKeyValue(valueLabels)(dataKey),
        data,
        tension: 0.5,
        borderColor: '#172c66',
        backgroundColor: '#172c66'
      }]
    }
  }, [weatherData, dataKey]);

  useEffect(() => {
    if (weatherChart && chartWeatherData) {
      weatherChart.data = chartWeatherData;

      weatherChart.options.scales = DEFAULTS_SCALES;

      if (weatherChart.options.scales.y?.ticks) {
        weatherChart.options.scales.y.ticks.callback = (val: any, index: number) => {
          return `${val}${valueUnits[dataKey]}`;
        }
      }

      weatherChart.update();
    }
  }, [weatherChart, chartWeatherData, dataKey]);

  useEffect(() => {
    const config = {
      type: lineType,
      data: {
        labels: [],
        datasets: []
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: DEFAULTS_SCALES
      },

    };

    if (weatherChart) {
        weatherChart.destroy();
    }

    setWeatherChart(canvasEl ? new Chart(canvasEl, config) : null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasEl]);

  useEffect(() => {
    if (weatherChart && chartWeatherData) {
      weatherChart.data = chartWeatherData;

      weatherChart.options.scales = DEFAULTS_SCALES;

      if (weatherChart.options.scales.y?.ticks) {
        weatherChart.options.scales.y.ticks.callback = (val: any, index: number) => {
          return `${val}${valueUnits[dataKey]}`;
        }
      }

      weatherChart.update();
    }
  }, [weatherChart, chartWeatherData, dataKey]);


  return (
    <div className={className}>
      <RadioGroup value={dataKey} onChange={setDataKey} className="u-flex u-flex--wrap u-flex--justify-center u-margin-bottom">
        <RadioGroup.Label className="u-visually-hidden">Select value</RadioGroup.Label>
        <RadioGroup.Option value="temp">
          {({ checked }) => (
            <span className={classnames("c-button c-button--tiny u-margin-right-tiny u-margin-bottom-tiny", !checked && "c-button--primary")}>{valueLabels.temp}</span>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option value="feels_like">
          {({ checked }) => (
            <span className={classnames("c-button c-button--tiny u-margin-right-tiny u-margin-bottom-tiny", !checked && "c-button--primary")}>{valueLabels.feels_like}</span>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option value="wind_speed">
          {({ checked }) => (
            <span className={classnames("c-button c-button--tiny u-margin-right-tiny u-margin-bottom-tiny", !checked && "c-button--primary")}>{valueLabels.wind_speed}</span>
          )}
        </RadioGroup.Option>
      </RadioGroup>

      <canvas id="chart" ref={el => setCanvasEl(el)}></canvas>
    </div>
  );
};

export default WeatherChart;
